import { InputProps, SelectProps, useField } from 'onekijs';
import React, { FC, FunctionComponent, useMemo } from 'react';
import ReactSelect from 'react-select';
import FormEntry from './FormEntry';
import FormLabel from './FormLabel';

const adapter = (nextValues?: ReactSelectSimpleOptions[]) => {
  if (nextValues) {
    return nextValues.map(nextValue => nextValue.value);
  }
  return [];
}

const FormElement: FC<SelectProps & {options: ReactSelectOptions[]}> = (props) => {
  // const validation = useValidation(props.name);
  // const values: string[] = useValue(props.name as string);
  // const { setValue } = useFormContext();
  const {name, value, onChange} = useField(props.name as string, props.validators, {
    defaultValue: props.defaultValue === undefined ? [] : props.defaultValue as string[],
  });
  
  const selectedValues = useMemo(() => {
    const flattenOptions = props.options.flatMap(option => {
      if (isReactSelectGroupOptions(option)) {
        return option.options;
      } else {
        return option;
      }
    });
    return value.map((v: string) => flattenOptions.find(option => option.value === v));
  }, [value, props.options]);
 
  return (
    <>
      <ReactSelect
        isMulti
        styles={{
          groupHeading: (provided, state) => ({
            ...provided,
            color: '#5a67d8',
            fontWeight: 'bold'
          })
        }}
        name={name}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        value={selectedValues}
        placeholder={props.placeholder}
        onChange={(nextValues) => onChange(adapter(nextValues as any))}
      />
    </>
  );
};

export type ReactSelectSimpleOptions = {
  value: string;
  label: string;
}

export type ReactSelectGroupOptions = {
  label: string;
  options: ReactSelectSimpleOptions[]
}

function isReactSelectGroupOptions(option: ReactSelectOptions): option is ReactSelectGroupOptions {
  return (option as ReactSelectGroupOptions).options !== undefined;
}

export type ReactSelectOptions = ReactSelectSimpleOptions | ReactSelectGroupOptions

export type FormMultiSelectEntryOptions = {
  label?: FunctionComponent | string;
  options: ReactSelectOptions[];
  errorMessage?: string;
  help?: string;
} & InputProps;
const FormMultiSelectEntry: FC<FormMultiSelectEntryOptions> = ({ label, ...options }) => {
  let Label: FC;
  if (label && typeof label === 'string')
    Label = () => <FormLabel htmlFor={options.name} required={options.required}>{label}</FormLabel>;
  else Label = label as FunctionComponent;
  return <FormEntry Label={Label} FormElement={FormElement} {...options}/>;
};

export default FormMultiSelectEntry;
