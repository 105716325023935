import { Input, InputProps, useValidation } from 'onekijs';
import React, { FC, FunctionComponent } from 'react';
import FormEntry from './FormEntry';
import FormLabel from './FormLabel';

const FormElement: FC<InputProps> = (props) => {
  const validation = useValidation(props.name);
  return (
    <>
      <Input
        className={`${props.className} ${
          props.type !== 'checkbox' ? 'appearance-none w-full block' : 'mr-4'
        } text-gray-700 border-gray-400 border rounded py-2 px-4 leading-tight focus:outline-none focus:border-4 focus:border-green-500 ${
          validation.isError() ? 'border-red-500' : ''
        } ${props.readOnly ? 'bg-gray-200' : ''}`}
        id={props.name}
        type="text"
        {...props}
      />
      {validation.isLoading() && <div>LOADING ...</div>}
    </>
  );
};

export type FormInputEntryOptions = {
  label?: FunctionComponent | string;
  labelAfter?: boolean;
  help?: string;
} & InputProps;
const FormInputEntry: FC<FormInputEntryOptions> = ({ label, help, ...options }) => {
  const style = {
    display: `${options.type !== 'checkbox' ? 'block' : 'inline-block'}`,
  };
  let Label: FC;
  if (label && typeof label === 'string')
    Label = () => (
      <FormLabel htmlFor={options.name} help={help} required={options.required} style={style}>
        {label}
      </FormLabel>
    );
  else Label = label as FunctionComponent;
  return (
    <FormEntry
      Label={Label}
      FormElement={FormElement}
      {...options}
      labelAfter={options.type !== 'checkbox' ? false : true}
    />
  );
};

export default FormInputEntry;
