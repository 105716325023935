import { secure, useForm, useNotificationService, useSecurePost, useSetting } from 'onekijs';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DefaultError from '../../modules/core/components/DefaultError';
import FormInputEntry from '../../modules/core/components/form/FormInputEntry';
import FormSubmitEntry from '../../modules/core/components/form/FormSubmitEntry';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../modules/core/libs/rbac';
import SubmitLabel from '../../modules/devtools/components/release-branch/SubmitLabel';


const ReleaseBranchCustomPage: FC = () => {
  const notificationService = useNotificationService();
  const baseUrl = useSetting('server.baseUrl');
  const [submit] = useSecurePost(`${baseUrl}/devtools/repositories/release-branches`, {
    onSuccess: () => {
      notificationService.success({
        message: 'Branch created successfully !' 
      });
      resetForm();
    },
  });

  const { Form, reset: resetForm } = useForm(submit);
  return (
    <PageLayout title="Release Branch" titleColored="Creator">
      <>
        <p className="text-gray-600">This tool helps you to create a release branch with a custom name</p>
        <p className="text-red-600 mt-2">
          <b>
            This is not recommended to specify the release version without following the standard. Only do it if you
            know what you are doing !
          </b>
          <br />
          <Link to="./release-branches" className="text-blue-500">
            Go back to standard release branch creator
          </Link>{' '}
        </p>

        <Form className="w-full pt-10">
          <FormInputEntry
            label="Git Repository"
            name="repo"
            placeholder="ethias-oi-demo-cap"
            required={true}
            requiredMessage="Please indicate a GIT repository"
          />
          <FormInputEntry
            label="Branch name"
            name="branchName"
            placeholder="release/1.0.0"
            required={true}
            requiredMessage="Please indicate a branch name"
          />
          <FormSubmitEntry label={SubmitLabel} />
        </Form>
      </>
    </PageLayout>
  );
};

export default secure(ReleaseBranchCustomPage, canReadWriteDevTools, { ErrorComponent: DefaultError});
