import React, { FC } from 'react';

export type SiteLayoutProps = {
  title: string;
  titleColored?: string;
};
const PageLayout: FC<SiteLayoutProps> = ({ title, titleColored, children }) => {
  return (
    <div className="flex bg-gray-200">
      <div className="flex text-left px-8 md:px-12 w-full flex-col" style={{ marginTop: '6rem' }}>
        <div className="px-5 py-5 bg-white w-full rounded-md shadow-md mb-10">
          <h3 className="font-semibold text-gray-800 text-3xl mb-5">
            {title}{' '}
            {titleColored && (
              <span className="text-green-600" style={{ color: '#7cc45c' }}>
                {titleColored}
              </span>
            )}
          </h3>
          {children}
        </div>
        <div className="px-5 py-4 flex justify-end"></div>
      </div>
    </div>
  );
};

export default PageLayout;
