import {
  AnonymousObject,
  asyncHttp,
  CollectionFetcher,
  CollectionItemAdapter,
  isCollectionLoading,
  ItemMeta,
  useCollection,
  useGlobalProp,
  useSetting,
} from 'onekijs';
import React, { FC, MouseEvent, useCallback, useRef, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FaEdit, FaPlus, FaRegTrashAlt, FaSpinner } from 'react-icons/fa';
import Table from '../../../core/components/table/Table';
import Tbody from '../../../core/components/table/Tbody';
import Th from '../../../core/components/table/Th';
import Thead from '../../../core/components/table/Thead';
import Tr from '../../../core/components/table/Tr';
import { User } from '../../typing';
import CreateUser from './CreateUser';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';

const userAdapter: CollectionItemAdapter<User, ItemMeta> = (user) => {
  return {
    id: user?.username,
  };
};

export type UserListOptions = {
  limit: number;
};

const dateFromISO8601 = (isostr: string | undefined) => {
  if (!isostr) return '';
  const year = isostr.substring(0, 4);
  const month = isostr.substring(5, 7);
  const day = isostr.substring(8, 10);
  return `${day}-${month}-${year}`;
};

const UserList: FC<UserListOptions> = ({ limit }) => {
  const baseUrl = useSetting('server.baseUrl');
  const paginationTokenRef = useRef<AnonymousObject>({});
  const auth = useGlobalProp('auth');
  const [selectedUsername, setSelectedUsername] = useState<string>();
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalCreateOpen, setModalCreateOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);

  const editUser = useCallback(
    (id: string) => {
      setSelectedUsername(id);
      setModalEditOpen(true);
    },
    [setSelectedUsername],
  );

  const deleteUser = useCallback(
    (e:MouseEvent, id: string) => {
      setSelectedUsername(id);
      setModalDeleteOpen(true);
      e.stopPropagation();
    },
    [setSelectedUsername, setModalDeleteOpen],
  );

  const fetcher: CollectionFetcher<User> = useCallback(
    async (url, method, body, options) => {
      let offset = 0;
      if (options?.query?.offset) {
        offset = parseInt(options.query.offset);
        options.query.offset = paginationTokenRef.current[offset];
      }
      const result: { paginationToken: string | null | undefined; data: User[] } = await asyncHttp(
        url,
        method,
        body,
        options,
      );

      if (result.paginationToken) {
        paginationTokenRef.current[offset + limit] = result.paginationToken;
      }
      return result;
    },
    [limit],
  );

  const collection = useCollection(`${baseUrl}/users`, {
    initialLimit: limit,
    adapter: userAdapter,
    autoload: true,
    fetcher,
    auth,
  });

  const closeEditUser = useCallback(() => {
    setModalEditOpen(false);
    collection.refresh();
  }, [setModalEditOpen, collection]);

  const closeCreateUser = useCallback(() => {
    setModalCreateOpen(false);
    collection.refresh();
  }, [setModalCreateOpen, collection]);

  const closeDeleteUser = useCallback(() => {
    setModalDeleteOpen(false);
    collection.refresh();
  }, [setModalDeleteOpen, collection]);

  return (
    <div className="mb-4 mt-8">
      <div className="mb-4">
        <button
          onClick={() => setModalCreateOpen(true)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          <FaPlus className="inline-block -mt-1 mr-1" /> Add user
        </button>
        {isCollectionLoading(collection) && <FaSpinner className="float-right text-xl mt-6 text-gray-700" />}
        {!isCollectionLoading(collection) && <BiRefresh className="float-right text-2xl mt-6 text-gray-700 cursor-pointer" onClick={() => collection.refresh()}/>}
      </div>
      <Table collection={collection} nbRows={limit}>
        <Thead>
          <Th field="username" filter="input">
            Username
          </Th>
          <Th field="firstname" filter="input">
            First name
          </Th>
          <Th field="lastname" filter="input">
            Last name
          </Th>
          <Th field="email" filter="input">
            Email
          </Th>
          <Th field="status">Status</Th>
          <Th field="updatedAt">Updated at</Th>
          <Th field=""></Th>
        </Thead>
        <Tbody
          Tr={(props) => (
            <Tr {...props} onClick={() => editUser(props.item?.data?.username)}>
              <td className="px-6 py-2">{props.item?.data?.username}</td>
              <td className="px-6 py-2">{props.item?.data?.firstname}</td>
              <td className="px-6 py-2">{props.item?.data?.lastname}</td>
              <td className="px-6 py-2">{props.item?.data?.email}</td>
              <td className="px-6 py-2">{props.item?.data?.status}</td>
              <td className="px-6 py-2">{dateFromISO8601(props.item?.data?.updatedAt)}</td>
              <td className="px-6 py-2">
                <FaEdit className="inline-block mr-1" onClick={() => editUser(props.item?.data?.username)} />{' '}
                <FaRegTrashAlt className="inline-block text-red-500" onClick={(e) => deleteUser(e, props.item?.data?.username)} />
              </td>
            </Tr>
          )}
        ></Tbody>
      </Table>
      {selectedUsername && modalEditOpen && (
        <EditUser isOpen={modalEditOpen} onClose={closeEditUser} username={selectedUsername} />
      )}
      {modalCreateOpen && <CreateUser isOpen={modalCreateOpen} onClose={closeCreateUser} />}
      {selectedUsername && modalDeleteOpen && (
        <DeleteUser isOpen={modalDeleteOpen} onClose={closeDeleteUser} username={selectedUsername} />
      )}
    </div>
  );
};

export default UserList;
