import React, { FC } from 'react';
import { BiGitBranch } from 'react-icons/bi';

const SubmitLabel: FC = () => (
  <>
    <BiGitBranch className="inline-block mr-2" style={{ marginTop: '-4px' }} />
    Create
  </>
);

export default SubmitLabel;
