import React, { FC } from 'react';
import { GrDocumentTest } from 'react-icons/gr';
import { MenuContext } from '../typings/api';

export type TestReportMenuEntryProps = {
  context: MenuContext;
  report: string;
  isOpen: boolean;
};

const TestReportMenuEntry: FC<TestReportMenuEntryProps> = ({ context, report, isOpen }) => {
  return (
    <li>
      <p
        className={`flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium focus:bg-gray-200 focus:shadow-outline${
          isOpen ? '' : ' cursor-pointer'
        }`}
        onClick={() => !isOpen && context.onTestSelect(report, 'unit')}
      >
        <span className="text-gray-600">
          <GrDocumentTest className="h-5" />
        </span>
        <span>{report}</span>
      </p>
      {isOpen && (
        <TestReportMenuContent
          context={context}
        />
      )}
    </li>
  );
};

type testReportMenuContentProps = {
  context: MenuContext;
};

const TestReportMenuContent: FC<testReportMenuContentProps> = ({ context }) => {
  return (
    <>
      {(!context.hasTests || context.loading) && <div>loading ...</div>}
      {context.hasTests && ! context.loading && (
        <div>
          {context.hasTests.unit && (
            <TestReportSubMenuEntry
              context={context}
              type="unit"
              selected={context.type === 'unit'}
            />
          )}

          {context.hasTests.integration && (
            <TestReportSubMenuEntry
              context={context}
              type="integration"
              selected={context.type === 'integration'}
            />
          )}

          {context.hasTests.jacoco && (
            <TestReportSubMenuEntry
              context={context}
              type="jacoco"
              selected={context.type === 'jacoco'}
            />
          )}
        </div>
      )}
    </>
  );
};

type TestReportSubMenuEntryProps = {
  context: MenuContext;
  type: string;
  selected: boolean;
};

const displayType = (type: string) => {
  if (type === 'unit') return 'Unit tests';
  if (type === 'integration') return 'Integration tests';
  if (type === 'jacoco') return 'Tests coverage';
  return '';
};

const TestReportSubMenuEntry: FC<TestReportSubMenuEntryProps> = ({ context, type, selected }) => {
  return (
    <p
      className={`ml-3 mb-1 cursor-pointer space-x-3 text-gray-600 px-2 rounded-md ${
        selected ? 'bg-gray-300' : ''
      } focus:bg-gray-200 focus:shadow-outline`}
      onClick={() => context.onTestSelect(context.report, type)}
    >
      {displayType(type)}
    </p>
  );
};

export default TestReportMenuEntry;
