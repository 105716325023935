import { AnonymousObject, asyncPost, DefaultLocalService, get, reducer, saga, SagaEffect, service } from 'onekijs';
import { ProjectGeneratorResponse, ProjectGeneratorState } from './typing';

export const initialState: ProjectGeneratorState = {
  showModal: false,
  loading: false,
  step: 'Creating main Github Repository',
  result: 0,
  errorMessage: '',
  outputLog: [],
  repositories: [],
};

@service
export default class ProjectGeneratorService extends DefaultLocalService<ProjectGeneratorState> {
  @reducer
  setLoading(loading: boolean): void {
    this.state.loading = loading;
  }

  @reducer
  setShowModal(showModal: boolean): void {
    this.state.showModal = showModal;
  }

  @reducer
  handleResponse(response: ProjectGeneratorResponse, nextStep: string) {
    this.state.step = nextStep;
    if (response.outputLog) this.state.outputLog = this.state.outputLog.concat(response.outputLog);
    this.state.result = response.result;
    if (response.errorMessage) {
      this.state.errorMessage = response.errorMessage;
    }
    if (response.repositories) {
      this.state.repositories = this.state.repositories.concat(response.repositories);
    }
    if (response.result !== 0) {
      // An error occured
      this.state.loading = false;
    }
  }

  @reducer
  reset() {
    Object.assign(this.state, initialState);
  }

  @saga(SagaEffect.Latest)
  *createProject(answers: AnonymousObject) {
    const auth = this.context.store.getState().auth;
    const baseUrl = get<string>(this.context.settings, 'server.baseUrl', '');
    yield this.setLoading(true);
    yield this.setShowModal(true);

    // Create the Github repositories
    let response: ProjectGeneratorResponse = yield asyncPost(
      `${baseUrl}/devtools/project-generator/github-repositories`,
      answers,
      { auth },
    );
    yield this.handleResponse(response, 'Creating IAC Github repository');

    if (response.result === 0) {
      // Create the Github IAC repository
      response = yield asyncPost(`${baseUrl}/devtools/project-generator/github-iac-repositories`, answers, { auth });
      yield this.handleResponse(response, 'Creating ECR repository');
    }

    if (response.result === 0) {
      // Create the ECR repository
      response = yield asyncPost(`${baseUrl}/devtools/project-generator/ecr-repositories`, answers, { auth });
      yield this.handleResponse(response, 'Updating the project generator to latest version');
    }

    if (response.result === 0) {
      // Initialize the projet generator
      response = yield asyncPost(`${baseUrl}/devtools/project-generator/initializers`, answers, { auth });
      yield this.handleResponse(response, 'Executing the project generator and commiting the code');
    }

    if (response.result === 0) {
      // Execute project generator and commit the code
      response = yield asyncPost(`${baseUrl}/devtools/project-generator/executors`, answers, { auth });
      yield this.handleResponse(response, 'Set default branch to develop');
    }

    if (response.result === 0) {
      // Set default branch to develop
      response = yield asyncPost(
        `${baseUrl}/devtools/project-generator/github-repositories/${answers.gitPrefix}${answers.serviceName}-${answers.apiType}/default-branches`,
        { branchName: 'develop' },
        { auth },
      );
      yield this.handleResponse(response, 'Open a pull request to add the projet to the list of projects monitored by Argo CD');
    }

    if (response.result === 0) {
      // Open a pull request to add the projet to the list of projects monitored by Argo CD
      response = yield asyncPost(`${baseUrl}/devtools/project-generator/argocd-pull-requests`, answers, { auth });
      yield this.handleResponse(response, '');
    }

    this.setLoading(false);
  }
}
