import { secure, useLocation, useRouter, useSecureGet, useSetting } from 'onekijs';
import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import TestReport from '../../modules/apis/components/TestReport';
import TestReportMenu from '../../modules/apis/components/TestReportMenu';
import { extractParams } from '../../modules/apis/libs/testReport';
import { HasTests, MenuContext } from '../../modules/apis/typings/api';
import DefaultError from '../../modules/core/components/DefaultError';
import { canReadDocs } from '../../modules/core/libs/rbac';

let TestReportPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const baseUrl = useSetting('server.baseUrl');
  const testReportUrl = useSetting('server.testReportUrl');
  const router = useRouter();
  const location = useLocation();
  const { type, report } = extractParams(router);
  const [testReports, loading] = useSecureGet<string[]>(`${baseUrl}/open-apis/${id}/test-reports`);
  const [selectedHasTests, selectedLoading] = useSecureGet<HasTests>(
    `${baseUrl}/open-apis/${id}/test-reports/${report}/existence`,
  );

  const onTestSelect = useCallback(
    (report: string, type: string) => {
      router.push(`${location.pathname}?report=${report}&type=${type}`);
    },
    [router, location],
  );

  const context: MenuContext = {
    apiName: id,
    report,
    type,
    testReports,
    onTestSelect,
    hasTests: selectedHasTests,
    loading: selectedLoading,
  }


  if (type === 'unit' && selectedHasTests && !selectedHasTests.unit) {
    if (selectedHasTests.integration) {
      context.type = 'integration';
    } else {
      context.type = 'jacoco';
    }
  }


  if (loading || !testReports) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="flex flex-wrap bg-gray-100 w-full h-screen" style={{marginTop: '65px'}}>
      <aside className="w-3/12 bg-white rounded p-3 shadow-lg" style={{width: '200px'}}>
        <TestReportMenu context={context}/>
      </aside>
      <section className="w-9/12" style={{flexGrow: 1}}>
        {context.hasTests && <TestReport baseUrl={testReportUrl} id={id} report={report} type={context.type} />}
      </section>
    </div>    
  );
};

TestReportPage = secure(TestReportPage, canReadDocs, { ErrorComponent: DefaultError });
export default TestReportPage;

/* <div style={{ marginTop: '6rem' }}>
       {selectedType !== '' && (
         <div className="float-right test-reports-select">
           <select value={selectedType} onChange={handleChangeType}>
             <option value=""></option>
             <option value="test">Unit tests</option>
             <option value="integrationTest">Integration tests</option>
           </select>
         </div>
       )}
       <TestReport baseUrl={testReportUrl} id={id} type={selectedType} />
     </div> */