import { secure, useForm, useNotificationService, useSecureGet, useSecurePost, useSetting } from 'onekijs';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DefaultError from '../../modules/core/components/DefaultError';
import FormInputEntry from '../../modules/core/components/form/FormInputEntry';
import FormSelectEntry from '../../modules/core/components/form/FormSelectEntry';
import FormSubmitEntry from '../../modules/core/components/form/FormSubmitEntry';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../modules/core/libs/rbac';
import MajorLabel from '../../modules/devtools/components/release-branch/MajorLabel';
import NextReleaseBranch, { NextRelease } from '../../modules/devtools/components/release-branch/NextReleaseBranch';
import SelectGitRepository from '../../modules/devtools/components/release-branch/SelectRepository';
import SubmitLabel from '../../modules/devtools/components/release-branch/SubmitLabel';

const ReleaseBranchPage: FC = () => {
  const notificationService = useNotificationService();
  const baseUrl = useSetting('server.baseUrl');
  const [submit] = useSecurePost(`${baseUrl}/devtools/repositories/release-branches`, {
    onSuccess: () => {
      notificationService.success({
        message: 'Branch created successfully !' 
      });
    },
  });
  const { Form, values/*, reset: resetForm*/ } = useForm(submit, {
    initialValues: {
      env: 'Tst',
    },
  });
  const [nextRelease] = useSecureGet<NextRelease>(
    `${baseUrl}/devtools/repositories/release-branches/next?env=${values.env}&repo=${values.repo || 'tbd'}`,
  );

  return (
    <PageLayout title="Release Branch" titleColored="Creator">
      <>
        <p className="text-gray-600">
          A new release branch should respect some standard. A developer must use this tool to create a new one
          <br />
          If you don't want to follow the standards and know what you are doing, use the{' '}
          <Link to="./release-branches-custom" className="text-blue-500">
            custom release branch creator
          </Link>
        </p>
        <Form className="w-full pt-10">
          <SelectGitRepository name="repo" required={true} requiredMessage="Please indicate a GIT repository" />
          <FormSelectEntry
            name="env"
            label="Environment"
            required={true}
            requiredMessage="Please indicate a environment"
          >
            <option key="Tst" value="Tst">Test</option>
            <option key="Acc" value="Acc">Acceptance</option>
          </FormSelectEntry>
          <FormInputEntry name="major" label={MajorLabel} type="checkbox" labelAfter={true} />
          <NextReleaseBranch nextRelease={nextRelease} values={values} />
          <FormSubmitEntry label={SubmitLabel} />
        </Form>
      </>
    </PageLayout>
  );
};

export default secure(ReleaseBranchPage, canReadWriteDevTools, { ErrorComponent: DefaultError});
