import { InputProps, Textarea, TextareaProps, useValidation } from 'onekijs';
import React, { FC, FunctionComponent } from 'react';
import FormEntry from './FormEntry';
import FormLabel from './FormLabel';

const sizes: any = {
  'xs': 'h-10',
  'sm': 'h-24',
  'lg': 'h-40',
  'xl': 'h-64',
}

const FormElement: FC<TextareaProps & {height?: string}> = (props) => {
  const validation = useValidation(props.name);
  const {height, ...opts } = props;
  return (
    <>
      <Textarea
        className={`no-resize appearance-none w-full text-gray-700 border border-gray-400 rounded py-2 px-4 leading-tight focus:outline-none focus:border-4 focus:border-green-500 focus:border-gray-500 ${
          validation.isError() ? 'border-red-500' : ''
        } ${height ? sizes[height] : 'h-40'}`}
        id={props.name}
        type="text"
        {...opts}
      />
    </>
  );
};

export type FormTextareaEntryOptions = {
  label?: FunctionComponent | string;
  labelAfter?: boolean;
  help?: string;
  height?: 'xs' | 'sm' | 'lg' | 'xl';
} & InputProps;
const FormTextareaEntry: FC<FormTextareaEntryOptions> = ({ label, ...options }) => {
  let Label: FC;
  if (label && typeof label === 'string')
    Label = () => <FormLabel htmlFor={options.name} required={options.required}>{label}</FormLabel>;
  else Label = label as FunctionComponent;
  return <FormEntry Label={Label} FormElement={FormElement} {...options}/>;
};

export default FormTextareaEntry;
