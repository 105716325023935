import { secure, useForm, useSecurePost, useSetting } from 'onekijs';
import React, { FC, useState } from 'react';
import DefaultError from '../../modules/core/components/DefaultError';
import FormInputEntry from '../../modules/core/components/form/FormInputEntry';
import FormSelectEntry from '../../modules/core/components/form/FormSelectEntry';
import FormSubmitEntry from '../../modules/core/components/form/FormSubmitEntry';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../modules/core/libs/rbac';
import EncryptedPassword from '../../modules/devtools/components/encryptor/EncyptedPassword';
import SubmitLabel from '../../modules/devtools/components/release-branch/SubmitLabel';


const EncryptorPage: FC = () => {
  const [encryptedPassword, setEncryptedPassword] = useState<string | undefined>(undefined);
  const [copied, setCopied] = useState(false);
  const baseUrl = useSetting('server.baseUrl');
  const [submit, submitLoading] = useSecurePost(`${baseUrl}/devtools/cyphertexts`, {
    onSuccess: (data) => {
      setEncryptedPassword(data.cyphertext);
      setCopied(false);
    },
  });

  const { Form } = useForm(submit, {
    initialValues: {
      env: 'Local',
      legacy: false
    },
  });

  return (
    <PageLayout title="Password" titleColored="Encryptor">
      <>
        <p>
          Any sensitive information stored in configuration files (application.yml) must be encrypted. This tool
          encrypts a string using the public key of the Kubernetes platform
        </p>

        <Form className="w-full pt-10">
          <FormInputEntry
            label="Plan text password"
            name="plaintext"
            placeholder="Password in clear"
            required={true}
            requiredMessage="Please indicate a branch name"
          />
          <FormSelectEntry name="env" label="Environment">
            <option value="Local">Local</option>
            <option value="Dev">Development</option>
            <option value="Tst">Test</option>
            <option value="Acc">Acceptance</option>
            <option value="Prd">Production</option>
          </FormSelectEntry>
          <FormInputEntry
            type="checkbox"
            label="Is legacy service (non OI compliant) ?"
            name="legacy"
          />
          <FormSubmitEntry label={SubmitLabel} />
        </Form>

        {encryptedPassword && !submitLoading && (
          <EncryptedPassword copied={copied} setCopied={setCopied} encryptedPassword={encryptedPassword} />
        )}
      </>
    </PageLayout>
  );
};

export default secure(EncryptorPage, canReadWriteDevTools, { ErrorComponent: DefaultError});
