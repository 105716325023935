import React, { FC } from 'react';
import { Link } from 'onekijs';

export type TestReportProps = {
  id: string;
  report: string;
  type?: string;
  baseUrl: string;
};

const onLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
  const obj = e.target as HTMLIFrameElement;
  if (obj) {
    let height = 0;
    if (obj.contentWindow?.document.documentElement.scrollHeight) {
      height = obj.contentWindow?.document.documentElement.scrollHeight + 20;
    }
    obj.style.height = height + 'px';
  }
  
}

const TestReport: FC<TestReportProps> = ({ id, report, type, baseUrl }) => {
  if (type === '') {
    return (
      <div className="test-reports-empty">
        <p className="title">No test available !</p>
        <p className="back"><Link href="/apis" style={{color: 'blue'}}>Back to API page</Link></p>
      </div>
    );
  }
  let url = `${baseUrl}/${id}/${report}/`;
  if (type === 'unit') url += 'tests/test';
  else if (type === 'integration') url += 'tests/integrationTest';
  else url += 'jacoco/test/html';
  url += '/index.html';

  return (
    <iframe className="test-reports" title="documentation" src={url} onLoad={onLoad}/>
  );
};

export default TestReport;

// 
